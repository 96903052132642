import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/styles/typography.css");
